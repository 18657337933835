import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [brandFilters, setBrandFilters] = useState({});
  const [genderFilters, setGenderFilters] = useState({});
  const [priceFilter, setPriceFilter] = useState("default");
  const [brandSearch, setBrandSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get("https://www.kcollection.az/kcollection/dashboard.php/", {
        withCredentials: false,
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      console.log(response.data);
      setProducts(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBrandChange = (brand) => {
    setBrandFilters((prev) => ({
      ...prev,
      [brand]: !prev[brand],
    }));
  };

  const handleGenderChange = (gender) => {
    setGenderFilters((prev) => ({
      ...prev,
      [gender]: !prev[gender],
    }));
  };

  const handlePriceChange = (price) => {
    setPriceFilter(price);
  };

  const clearBrandFilters = () => {
    setBrandFilters({});
    setBrandSearch("");
  };

  // Filtering logic
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if brand filters are applied
    const hasSelectedBrands = Object.keys(brandFilters).some(key => brandFilters[key]);
    const matchesBrand = !hasSelectedBrands || brandFilters[product.brand];

    // Check if gender filters are applied
    const hasSelectedGenders = Object.keys(genderFilters).some(key => genderFilters[key]);
    const matchesGender = !hasSelectedGenders || genderFilters[product.gender];

    return matchesSearch && matchesBrand && matchesGender;
  });

  // Sort products based on the selected option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === "lowToHigh") {
      return a.price - b.price; // Sort ascending by price
    } else if (sortOption === "highToLow") {
      return b.price - a.price; // Sort descending by price
    }
    return 0; // No sorting
  });

  // Apply price filter
  const finalProducts = priceFilter === "default" ? sortedProducts : sortedProducts.filter(product => {
    if (priceFilter === "low") return product.price > 100 && product.price < 200; // Example: products priced below 50
    if (priceFilter === "high") return product.price > 200 && product.price < 300; // Example: products priced 50 and above
    if (priceFilter === "highh") return product.price > 300 && product.price < 400; // Example: products priced 50 and above
    if (priceFilter === "highhh") return product.price > 400 && product.price < 500; // Example: products priced 50 and above

    return true;
  });

  // Brands to display (for example purposes)
  const allBrands = [
    "brand1", "brand2", "brand3", "brand4", "brand5",
    "brand6", "brand7", "brand8", "brand9", "brand10",
    "brand11", "brand12", "brand13", "brand14", "brand15",
    "brand16", "brand17", "brand18", "brand19", "brand20"
  ];

  // Filter brands based on search input
  const filteredBrands = allBrands.filter(brand =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

  return (
    <>
      <div className='pb-[44px] bg-[black]'>
        <p className='text-[#DDB14C] text-4xl font-medium pt-16 text-center'>Məhsullar</p>
        
        <div className='flex max-w-container mx-auto mt-8 justify-between'>
          {/* Left Filter Section */}
          <div className="w-[209px]">
            <h4 className='text-white text-xl font-medium'>Filtrlər:</h4>

            <div className="mt-4">
              <label className='text-white font-medium mb-7'>Kateqoriya:</label>
              <div className="flex flex-col">
                {["men", "women", "unisex"].map((gender) => (
                  <div key={gender}>
                    <input
                      type="checkbox"
                      id={gender}
                      checked={!!genderFilters[gender]}
                      onChange={() => handleGenderChange(gender)}
                    />
                    <label htmlFor={gender} className="ml-2 text-white">{gender.charAt(0).toUpperCase() + gender.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>
            
            <div className="mt-4">
             <div className="flex justify-between">
             <label className='text-white'>Brendlər:</label>
              <button
                  onClick={clearBrandFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
             </div>
              <div className="flex items-center mt-1">
                <input
                  type="text"
                  value={brandSearch}
                  onChange={(e) => setBrandSearch(e.target.value)}
                  placeholder="Axtar..."
                  className="mt-4 p-2 rounded-2xl w-[210px] bg-[#020202] border-[#595959] border-2 text-[white] mb-4"
                />
              </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48">
                {filteredBrands.map((brand) => ( // Show only the first 3 brands
                  <div key={brand}>
                    <input
                      type="checkbox"
                      id={brand}
                      checked={!!brandFilters[brand]}
                      onChange={() => handleBrandChange(brand)}
                    />
                    <label htmlFor={brand} className="ml-2 text-white">{brand.charAt(0).toUpperCase() + brand.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>
            
            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>
            
            <div className="mt-4">
              <label className='text-white'>Qiymət:</label>
              <div className="flex flex-col">
               <div className="flex">
               <input
                  type="radio"
                  id="allPrices"
                  name="price"
                  value="default"
                  checked={priceFilter === "default"}
                  onChange={() => handlePriceChange("default")}
                />
                <label htmlFor="allPrices" className="text-white">Bütün qiymətlər</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under200"
                  name="price"
                  value="low"
                  checked={priceFilter === "low"}
                  onChange={() => handlePriceChange("low")}
                />
                <label htmlFor="under200" className=" text-white">100-200 azn</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under300"
                  name="price"
                  value="high"
                  checked={priceFilter === "high"}
                  onChange={() => handlePriceChange("high")}
                />
                <label htmlFor="under300" className=" text-white">200-300 AZN</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under400"
                  name="price"
                  value="highh"
                  checked={priceFilter === "highh"}
                  onChange={() => handlePriceChange("highh")}
                />
                <label htmlFor="under400" className=" text-white">300-400 AZN</label>
               </div>

               <div className="flex">
               <input
                  type="radio"
                  id="under500"
                  name="price"
                  value="highhh"
                  checked={priceFilter === "highhh"}
                  onChange={() => handlePriceChange("highhh")}
                />
                <label htmlFor="under500" className=" text-white">400-500 AZN</label>
               </div>
               
              </div>
            </div>

            
          </div>

          {/* Products Section */}
          <div className="w-[853px]">
            <div className="flex justify-between mb-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Axtar..."
              className="mt-4 p-2 rounded-2xl w-[415px] bg-[#020202] border-[#595959] border-2 text-[white]"
            />
            
            {/* Sort Options */}
            <div className="mt-4">
              <label className='text-white'>Çeşidlə:</label>
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="mt-1 p-2 rounded-2xl bg-[#020202] text-[white] border-[#595959] border-2 ml-2"
              >
                <option value="default">Sonuncu</option>
                <option value="lowToHigh">Ən ucuz qiymətdən</option>
                <option value="highToLow">Ən baha qiymətdən</option>
              </select>
            </div>
            </div> 
            {isLoading ?  <p className="text-[white]"> Loading...</p> :
              <PaginatedItems itemsPerPage={12} items={finalProducts} />  
            }
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;



















































