import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import { product1, product2, product3, product4 } from '../../../assets/images';

const Products = () => {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.kcollection.az/kcollection/dashboard.php/", {
        credentials: 'omit'
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // Limit to the first 8 items
      setItems(data.slice(0, 8));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <div className='bg-[#020202]'>
      <div className="max-w-container mx-auto py-16">
        <p className="text-center text-3xl font-medium mb-6 text-[#DFB66F]">Məhsullarımız</p>
       {isLoading ? <p className='text-[white]'>Loading...</p> :
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 px-4">
        {list.map((product) => (
          <a key={product.id} href={`/product-detail/${product.id}`}>
            <ProductCard
              name={product.name}
              gender={product.gender}
              volume={product.volume}
              price={product.price}
              image={product.image}
            />
          </a>
        ))}
      </div>}
      </div>
    </div>
  );
};

export default Products;
