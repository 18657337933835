import React from 'react'
import { about, product1, product2, product3, product4 } from '../../../assets/images';

const products = [
  {
    id: 1,
    title: 'Sofa pouffe',
    price: '100 Azn',
    image: product1, // Replace with the actual image path
    path: '/sofa'
  },
  {
    id: 2,
    title: 'Top pouffe',
    price: '100 Azn',
    image: product2, // Replace with the actual image path
     path: '/top'
  },
  {
    id: 3,
    title: 'Komfort pouffe',
    price: '100 Azn',
    image: product3, // Replace with the actual image path
    path:'/komfort',
    
  },
  {
    id: 4,
    title: 'Klassik pouffe',
    price: '100 Azn',
    image: product4, // Replace with the actual image path
     path: '/klassik'
  },
  {
    id: 1,
    title: 'Sofa pouffe',
    price: '100 Azn',
    image: product1, // Replace with the actual image path
    path: '/sofa'
  },
  {
    id: 2,
    title: 'Top pouffe',
    price: '100 Azn',
    image: product2, // Replace with the actual image path
     path: '/top'
  },
  {
    id: 3,
    title: 'Komfort pouffe',
    price: '100 Azn',
    image: product3, // Replace with the actual image path
    path:'/komfort',
    
  },
  {
    id: 4,
    title: 'Klassik pouffe',
    price: '100 Azn',
    image: product4, // Replace with the actual image path
     path: '/klassik'
  },
];

const About = () => {
  return (
  <div className='bg-[#020202]'>
      <div className="max-w-container mx-auto py-16 ">
    <p className="text-center text-3xl font-medium mb-6 text-[#DFB66F]">Haqqımızda</p>
   <div className='flex justify-between'>
    <div>
   <img src={about}></img>
    </div>
    <div className='flex justify-center items-center ml-10'>
        <p className='text-[white] w-2/3'>KCollection, zərif və yenilikçi parfüm kolleksiyalarımızla sizi öz dünyamıza dəvət edir. 2010-cu ildən bəri, biz lüks və mükəmməl qoxular yaratmağa özümüzü həsr etmişik. Hər bir ətirimiz, unikal tərkib hissələri və yüksək keyfiyyətli komponentlərlə hazırlanır, məqsədimiz isə müştərilərimizə həm estetik, həm də emosional bir təcrübə təqdim etməkdir.</p>
    </div>
   </div>
  </div>
  </div>
  )
}

export default About