import React from 'react';
import { poster } from '../../../assets/images';

const Poster = () => {
  return (
    <div className="relative">
      <img  src={poster} alt="Interior Design" className="w-full h-auto object-cover" />
    </div>
  );
};

export default Poster;

