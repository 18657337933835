import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

// Example items, to simulate fetching from another resource.
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

function Items({ currentItems }) {
  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch("https://www.kcollection.az/kcollection/dashboard.php/", {
        credentials: 'omit'
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='grid grid-cols-3 gap-4'>
        {currentItems && currentItems.map((item, index) => (
          <div className='bg-[green]' key={index}>
            <h3>Name #{item}</h3>
          </div>
        ))}
      </div>
    </>
  );
}

function PaginatedItems({ itemsPerPage, items }) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='grid grid-cols-3 gap-4'>
        {currentItems && currentItems.map((item, index) => (
            <div className='bg-[#3F3F3F] rounded-2xl w-[271px] h-[286px]' key={index}>
                        <a href={`product-detail/${item.id}`}>
            <div className='m-3 text-[white]'>
              <div className='bg-[white] rounded-xl h-[167px] mb-3 flex justify-center items-center'>
                <img className='mx-auto w-[147px] h-[147px]' src={`data:image/jpeg;base64,${item.image}`} alt='' />
              </div>
              <h3 className='text-left'>{item.name}</h3>
              <h3 className='text-left'>{item.gender} <span>{item.volume} ml</span></h3>
              <h3 className='text-[#dfb66f] text-left mt-2'>{item.price} AZN</h3>
            </div>
            </a>
          </div>
        ))}
      </div>
      <ReactPaginate
        nextLabel="sonrakı"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="əvvəlki"
        renderOnZeroPageCount={null}
        containerClassName="flex justify-center items-center space-x-2 my-4"
        pageClassName="inline-block px-3 py-1 text-center rounded bg-gray-200 hover:bg-gray-300"
        previousClassName="inline-block px-3 py-1 text-center rounded bg-gray-200 hover:bg-gray-300"
        nextClassName="inline-block px-3 py-1 text-center rounded bg-gray-200 hover:bg-gray-300"
        activeClassName="bg-blue-600 text-white"
        disabledClassName="text-gray-400 cursor-not-allowed"
      />
    </>
  );
}

export default PaginatedItems;

