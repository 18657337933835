import React, { useEffect, useState } from 'react'

const Contact = () => {


  return (
    <>
   <>
   <div className="bg-[black] h-[760px] flex justify-center items-center">
    <div className='w-[80%] mx-auto h-[630px]'>
  <p className='text-[#dfb66f] text-[32px] font-medium text-center'>Əlaqə</p>
  <p className='text-[20px] italic text-[#BCBCBC] text-center mb-12'>Bizə sualınız var? Məhsullarımıza rəy bildirmək istəyirsiniz? <br></br> Bizə yaza bilərsiz</p>
 <div className='h-[530px] flex mb-[40px] justify-between '>
 <div className='w-[19%] flex flex-col'>
 <div className='text-[white]'>
          <h4 className="text-lg font-semibold mb-2">Əlaqə məlumatlarınız</h4>
          <div className='flex'>
          <a href="#"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.7296 8.86029L17.8113 10.7985C18.7875 12.5476 18.3956 14.8422 16.8582 16.3797C16.8582 16.3797 16.8582 16.3797 16.8582 16.3797C16.8581 16.3798 14.9934 18.2447 18.3745 21.6258C21.755 25.0063 23.6198 23.143 23.6207 23.1422C23.6207 23.1421 23.6207 23.1421 23.6207 23.1421C25.1581 21.6047 27.4527 21.2128 29.2018 22.189L31.14 23.2707C33.7813 24.7447 34.0932 28.4487 31.7716 30.7703C30.3766 32.1653 28.6677 33.2508 26.7785 33.3224C23.5983 33.443 18.1975 32.6381 12.7798 27.2205C7.3622 21.8029 6.55735 16.402 6.67791 13.2218C6.74953 11.3327 7.835 9.62373 9.23 8.22872C11.5516 5.90714 15.2556 6.21907 16.7296 8.86029Z" fill="#DFB66F"/>
</svg>
</a>
          <p className='text-lg'>+994 50 340 10 70</p>
          </div>
          <div className='flex mt-2'>
          <a href="#"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.28563 8.61929C3.33301 10.5719 3.33301 13.7146 3.33301 20C3.33301 26.2854 3.33301 29.4281 5.28563 31.3807C7.23825 33.3333 10.3809 33.3333 16.6663 33.3333H23.333C29.6184 33.3333 32.7611 33.3333 34.7137 31.3807C36.6663 29.4281 36.6663 26.2854 36.6663 20C36.6663 13.7146 36.6663 10.5719 34.7137 8.61929C32.7611 6.66667 29.6184 6.66667 23.333 6.66667H16.6663C10.3809 6.66667 7.23825 6.66667 5.28563 8.61929ZM30.96 12.5331C31.4019 13.0635 31.3302 13.8517 30.7999 14.2936L27.1391 17.3443C25.6618 18.5754 24.4645 19.5732 23.4077 20.2529C22.3069 20.9609 21.2348 21.4081 19.9997 21.4081C18.7645 21.4081 17.6925 20.9609 16.5916 20.2529C15.5349 19.5732 14.3375 18.5754 12.8603 17.3443L9.19944 14.2936C8.6691 13.8517 8.59744 13.0635 9.0394 12.5331C9.48135 12.0028 10.2696 11.9311 10.7999 12.3731L14.3981 15.3715C15.953 16.6673 17.0326 17.564 17.944 18.1502C18.8262 18.7177 19.4246 18.9081 19.9997 18.9081C20.5748 18.9081 21.1731 18.7177 22.0554 18.1502C22.9668 17.564 24.0464 16.6673 25.6013 15.3715L29.1994 12.3731C29.7298 11.9311 30.518 12.0028 30.96 12.5331Z" fill="#DFB66F"/>
</svg>
</a>
          <p className='text-lg'>kcollection@gmail.com</p>
          </div>
        </div>
</div>
<div className='w-[60%]'>
<form class="space-y-8 text-[white]">
    <div class="grid grid-cols-1 gap-6"> 
      <div>
        <label for="company" class="block text-sm font-medium text-[white]">Ad, soyad</label>
        <input type="text" id="company" class="bg-[#181818] mt-2 block w-full px-4 py-3 border border-[#3D3D3D] rounded-md shadow-sm focus:outline-none sm:text-sm" placeholder="Ad, soyad"></input>
      </div>
    </div>
    <div>
        <label for="text" class="block text-sm font-medium text-[white]">E-mail</label>
        <input type="email" id="quantity" class="bg-[#181818] mt-2 block w-full px-4 py-3 border border-[#3D3D3D] rounded-md shadow-sm focus:outline-none sm:text-sm" placeholder="E-mail"></input>
      </div>
    <div>
        <label for="email" class="block text-sm font-medium text-[white]">Əlaqə nömrəsi</label>
        <input type="number" id="email" class="bg-[#181818] mt-2 block w-full px-4 py-3 border border-[#3D3D3D] rounded-md shadow-sm focus:outline-none sm:text-sm" placeholder="Əlaqə nömrəsi"></input>
      </div>

    <div>
      <label for="message" class="block text-sm font-medium text-[white]">Mesajınız</label>
      <textarea id="message" rows="4" class="bg-[#181818] mt-2 block w-full px-4 py-4 border border-[#3D3D3D] rounded-md shadow-sm focus:outline-none sm:text-sm" placeholder="Mesajınız"></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="bg-[#DDB14C] px-8 py-3 text-white rounded-md shadow-sm focus:outline-none focus:ring-opacity-50">Göndər</button>
    </div>
  </form> 
 </div>
 </div>
    </div>
    </div>
    </>
    </>
  )
}

export default Contact