import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';



const DashboardHeader = () => {

  const navigate = useNavigate(); 

  const handleLogout = () => {
    Swal.fire({
      title: 'Əminsiz?',
      text: "Admin paneldən çıxmaq istəyirsiz?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Bəli',
      cancelButtonText: 'Xeyr'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("login", "");
        localStorage.setItem("loginStatus", "Logged out successfully!");
        navigate("/");
        toast.error("Admin paneldən çıxıldı")
      }
    });
  };

  const user = localStorage.getItem("user");




  return ( 
    <>
      <header class="main-header">
            {/* <!-- Top Bar --> */}
            
            <div class="middle-bar">
            	<div class="auto-container">
                </div>
            </div>
            
            {/* <!-- Lower Section --> */}
            <div class="lower-section">
                <div class="auto-container">
                    
                    {/* <!--Outer Box--> */}
                    <div class="outer-box clearfix">
                         
                        {/* <!-- Hidden Nav Toggler --> */}
                        <div class="nav-toggler">
                        <button class="hidden-bar-opener"><span class="icon fa fa-bars"></span></button>
                        </div>
                        {/* <!-- / Hidden Nav Toggler --> */}
                         
                        
                        {/* <!-- Main Menu --> */}
                        <nav class="main-menu">
                            
                            <div class="navbar-header">
                                {/* <!-- Toggle Button -->    	 */}
                                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                            </div>
                            
                            <div class="navbar-collapse collapse clearfix">
                                <ul class="navigation">
                                    <li class="dropdown"><a href="/dashboard/pending">Təsdiq gözləyənlər</a></li>
                                    <li class="dropdown"><a href="/dashboard">Üzvlər</a></li>
                                    <li class="dropdown"><a href="/dashboard/offers">Təkliflər</a></li>
                                </ul>
                            </div>
                        </nav>
                        {/* <!-- Main Menu End--> */}
                        
                        <nav>
                        <ul>
      <li>
          <div onClick={handleLogout} className="link-box">
            <p className='theme-btn cursor-pointer'>{user}</p>
          </div>
      </li>
    </ul>

    </nav>
                        
                    </div>
                </div>
            </div>
            
        </header>
    </>
  );
};

export default DashboardHeader;




























