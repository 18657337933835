import React from 'react'



const ProductCard = ({ index, name, gender, volume, price, image }) => {
  return (
        <div className='bg-[#3F3F3F] rounded-2xl w-[271px] h-[286px]' key={index}>
            <div className='m-3 text-[white]'>
              <div className='bg-[white] rounded-xl h-[167px] mb-3 flex justify-center items-center'>
                <img className='w-5/6 mx-auto' src={`data:image/jpeg;base64,${image}`} alt='' />
              </div>
              <h3 className='text-left'>{name}</h3>
              <h3 className='text-left'>{gender} <span>{volume} ml</span></h3>
              <h3 className='text-[#dfb66f] text-left mt-2'>{price} AZN</h3>
            </div>
          </div>
  )
}

export default ProductCard