import React from 'react'
import DiscountCard from './DiscountCard';
import { discount1, discount2, discount3, hero1, hero2 } from '../../../assets/images';


const Discount = () => {
  return (
   <div className='bg-[black]'>
     <div className="max-w-container mx-auto flex justify-between gap-6 px-4 py-8 text-[white]">
      <div className='w-1/4'><img src={hero1}></img></div>
      <div className='mt-20'>
        <h1 className='text-3xl'>KCollection Parfümeriyada Zəriflik və Keyfiyyətin Yeni Adı</h1>
        <p>Bizim geniş ətir seçimimizlə sizin üçün mükəmməl ətri kəşf edin</p>
        <button className='bg-[#DFB66F] p-3 text-[black]'>İndi al</button>
        </div>
      <div className='w-1/5 flex flex-col justify-end'><img src={hero2}></img></div>
  </div>
   </div>
  )
}

export default Discount 