import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#DFB66F] text-gray-800 py-8">
      <div className="max-w-container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8">
        <div>
          <h3 className="text-xl font-semibold mb-2">KCollection</h3>
          <p>Parfümeriyada Zəriflik və Keyfiyyətin Yeni Adı</p>
        </div>
        <div>

        </div>
        <div>
          <h4 className="text-lg font-semibold mb-2">Əsas bağlantılar</h4>
          <ul>
            <li><a href="/" className="hover:underline">Ana səhifə</a></li>
            <li><a href="/about" className="hover:underline">Haqqımızda</a></li>
            <li><a href="/products" className="hover:underline">Məhsullar</a></li>
            <li><a href="/contact" className="hover:underline">Əlaqə</a></li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-2">Kateqoriyalar</h4>
          <ul>
            <li><a href="/products" className="hover:underline">Qadın ətirləri</a></li>
            <li><a href="/products" className="hover:underline">Kişi ətirləri</a></li>
            <li><a href="/products" className="hover:underline">Unisex ətirlər</a></li>
          </ul>
        </div>
        <div>
          <h4 className="text-lg font-semibold mb-2">Əlaqə vasitələri</h4>
          <div className='flex'>
          <a href="#"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0273 0H11.9727C5.36035 0 0 5.36035 0 11.9727V12.0273C0 18.6397 5.36035 24 11.9727 24H12.0273C18.6397 24 24 18.6397 24 12.0273V11.9727C24 5.36035 18.6397 0 12.0273 0Z" fill="#383D38"/>
<path d="M14.3388 15.51C11.2672 15.51 8.76827 13.0102 8.76743 9.93862C8.76827 9.16001 9.40226 8.52686 10.1792 8.52686C10.2591 8.52686 10.3381 8.53358 10.4138 8.54703C10.5803 8.57478 10.7383 8.63112 10.8838 8.71604C10.9048 8.72866 10.9191 8.74884 10.9225 8.77238L11.2471 10.8181C11.2513 10.8417 11.2437 10.8661 11.2277 10.8837C11.0486 11.0822 10.8199 11.2251 10.5651 11.2966L10.4424 11.331L10.4886 11.4496C10.9074 12.5158 11.76 13.3676 12.827 13.788L12.9455 13.8351L12.98 13.7123C13.0515 13.4575 13.1944 13.2288 13.3929 13.0497C13.4072 13.0363 13.4265 13.0295 13.4458 13.0295C13.45 13.0295 13.4543 13.0295 13.4593 13.0304L15.505 13.3549C15.5294 13.3591 15.5496 13.3726 15.5622 13.3936C15.6463 13.5391 15.7027 13.698 15.7312 13.8645C15.7447 13.9385 15.7506 14.0167 15.7506 14.0982C15.7506 14.876 15.1174 15.5092 14.3388 15.51Z" fill="#FDFDFD"/>
<path d="M19.7857 11.3264C19.6201 9.45469 18.7624 7.71836 17.3709 6.43777C15.9709 5.14961 14.1547 4.43994 12.2552 4.43994C8.08633 4.43994 4.69441 7.83187 4.69441 12.0007C4.69441 13.3999 5.08034 14.7629 5.81103 15.9502L4.18149 19.5573L9.39888 19.0015C10.3061 19.3732 11.2664 19.5615 12.2544 19.5615C12.5142 19.5615 12.7807 19.5481 13.0481 19.5203C13.2835 19.4951 13.5215 19.4581 13.7553 19.411C17.2464 18.7056 19.795 15.6071 19.8152 12.0411V12.0007C19.8152 11.7737 19.8051 11.5467 19.7849 11.3272L19.7857 11.3264ZM9.59984 17.4183L6.71326 17.726L7.57511 15.8165L7.40274 15.5852C7.39012 15.5684 7.37751 15.5516 7.36322 15.5323C6.61488 14.4989 6.21967 13.278 6.21967 12.0016C6.21967 8.67355 8.92717 5.96606 12.2552 5.96606C15.373 5.96606 18.0158 8.3986 18.2705 11.5038C18.284 11.6703 18.2916 11.8376 18.2916 12.0024C18.2916 12.0495 18.2907 12.0958 18.2899 12.1454C18.226 14.9294 16.2811 17.2938 13.5602 17.8959C13.3525 17.9421 13.1398 17.9774 12.9279 18.0001C12.7076 18.0253 12.4814 18.0379 12.2569 18.0379C11.4572 18.0379 10.6803 17.8832 9.94627 17.5772C9.86471 17.5444 9.78482 17.5091 9.70999 17.4729L9.60069 17.4199L9.59984 17.4183Z" fill="#FDFDFD"/>
</svg>
</a>
          <p>+994 55 340 10 70</p>
          </div>
          <div className='flex mt-2'>
          <a href="#"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0273 0H11.9727C5.36035 0 0 5.36035 0 11.9727V12.0273C0 18.6397 5.36035 24 11.9727 24H12.0273C18.6397 24 24 18.6397 24 12.0273V11.9727C24 5.36035 18.6397 0 12.0273 0Z" fill="#383D38"/>
<path d="M15.7135 4.88269H8.28723C6.23559 4.88269 4.56653 6.55175 4.56653 8.60339V15.3974C4.56653 17.449 6.23559 19.1181 8.28723 19.1181H15.7135C17.7651 19.1181 19.4342 17.449 19.4342 15.3974V8.60339C19.4342 6.55175 17.7651 4.88269 15.7135 4.88269ZM5.87908 8.60339C5.87908 7.27571 6.95955 6.19524 8.28723 6.19524H15.7135C17.0412 6.19524 18.1217 7.27571 18.1217 8.60339V15.3974C18.1217 16.725 17.0412 17.8055 15.7135 17.8055H8.28723C6.95955 17.8055 5.87908 16.725 5.87908 15.3974V8.60339Z" fill="white"/>
<path d="M12.0004 15.4603C13.9083 15.4603 15.4613 13.9082 15.4613 11.9995C15.4613 10.0908 13.9091 8.53857 12.0004 8.53857C10.0917 8.53857 8.53955 10.0908 8.53955 11.9995C8.53955 13.9082 10.0917 15.4603 12.0004 15.4603ZM12.0004 9.85196C13.1852 9.85196 14.1488 10.8156 14.1488 12.0003C14.1488 13.185 13.1852 14.1486 12.0004 14.1486C10.8157 14.1486 9.8521 13.185 9.8521 12.0003C9.8521 10.8156 10.8157 9.85196 12.0004 9.85196Z" fill="white"/>
<path d="M15.7817 9.09777C16.2955 9.09777 16.7142 8.67987 16.7142 8.16528C16.7142 7.65069 16.2963 7.23279 15.7817 7.23279C15.2671 7.23279 14.8492 7.65069 14.8492 8.16528C14.8492 8.67987 15.2671 9.09777 15.7817 9.09777Z" fill="white"/>
</svg>
</a>
          <p>kcollection baku</p>
          </div>
        </div>
      </div>
      <div className="mt-16 text-center text-sm">
        KCollection.az © Bütün hüquqlar qorunur.
      </div>
    </footer>
  );
};

export default Footer;
